input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    @include appearance;
}

.summary-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    background-color: #ffffff;
}

.summary-box {
    flex: 1;
    padding: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    text-align: center;
}

.summary-title {
    font-size: 14px;
    color: #555555;
    margin-bottom: 8px;
}

.summary-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.summary-value {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.summary-change {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.summary-change.positive {
    color: #28a745;
}

.arrow-up {
    font-size: 12px;
    margin-right: 4px;
}

.form-container {
    padding: 20px;
}

.form-label {
    font-weight: bold;
}

.table-responsive {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
}

.btn-primary {
    background-color: #6c63ff;
    border: none;
}

.btn-secondary {
    background-color: #cccccc;
    border: none;
}

.disabled-item {
    color: #ccc;
    /* Light gray for disabled */
    cursor: not-allowed;
    /* Indicate it is disabled */
    pointer-events: none;
    /* Prevent any interaction */
    opacity: 0.6;
    /* Make it look dimmed */
}

.main_freight_wrapper {
    .freight_tabing_wrap {
        ul.nav {
            gap: 12px;

            .tab_menu {
                @include borderRadius(12px);
                border: 1px solid $neutral_3;
                background: $white;
                padding: 0px 0px;
                height: 40px;
                @include font($font-weight-normal, 14px, normal, $neutral_5);
                @extend .flex-center;
                justify-content: center;

                .dropdown {
                    width: 100%;
                    height: 100%;

                    &>a {
                        display: block;
                        width: 100%;
                        background: transparent;
                        padding: 0;
                        box-shadow: none;
                        border: 0;
                        @include font($font-weight-normal, 14px, 38px, $neutral_5);
                        transition: 0s all;
                    }
                }

                &.active {
                    background: $primary_7;
                    border-color: $primary_7;
                    color: $white;

                    .dropdown {
                        &>a {
                            color: $white;
                        }
                    }
                }
            }

            .dropdown-menu {
                width: 100%;
                @include borderRadius(12px);
                background: $white;
                box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.20);
                margin: 10px 0 0;
                padding: 0;
                border: 0;

                .dropdown-item {
                    padding: 13px 16px;
                    @include font($font-weight-medium, 16px, normal, $neutral_8);
                    border-bottom: 1px solid $neutral_2;
                }
            }
        }
    }

    .table_wrap {
        table {
            td {
                &:not(:last-child) {
                    min-width: 130px;
                }
            }
        }
    }

    .upload_freight_wrap {
        padding: 0 70px;
        max-width: 1240px;
        margin: 0 auto;

        .nav .nav-item .disabled.nav-link {
            pointer-events: none;
            opacity: 0.4;
        }

        .upload_surcharges_row {
            position: relative;
            padding: 13px;
            border: 1px solid #4848F7;
            border-radius: 10px;
            background: rgb(241 241 254 / 50%);

            &:not(:last-child) {
                margin: 0 0 15px;
            }

            // &:not(:first-child){
            //     padding-top: 30px;
            //     margin-top: 20px;
            //     border-top: 1px solid #e9e9ef;
            //     border-top: 1px solid $neutral_2;
            // }
            .btn_wrap {
                position: absolute;
                bottom: 20px;
                right: -60px;

                button {
                    width: 35px;
                    height: 35px;
                    @include borderRadius(7px);
                    font-size: 16px;

                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            .form-select__control {
                .form-select__value-container {
                    max-height: 60px;
                    overflow-y: auto;
                }
            }
        }

        .dropzone-previews {
            .dz-image-preview {
                i {
                    width: 30px;
                    height: 30px;
                    display: block;
                    background: $primary_1;
                    @include borderRadius(100%);
                    text-align: center;
                    line-height: 30px;
                    font-size: 20px;
                    color: $primary_7;
                }
            }
        }

        .add_btn_box {
            height: 120px;
            width: 100%;
            border: 1px dashed $neutral_3;
            background: $neutral_1;
        }

        @media only screen and (max-width: 767px) {
            padding: 0;

            .upload_surcharges_row {
                padding-right: 30px;

                .btn_wrap {
                    bottom: 18px;
                    right: -12px;
                }
            }

            .dropzone {
                min-height: 130px;

                .dz-message {
                    padding: 20px;

                    h4 {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    // &.surcharges_add_form_wrap{
    //     .form-select__menu-list {
    //         .form-select__option:last-child {
    //             background: $primary_7;
    //             color: $white;
    //             @include borderRadius(8px);
    //             width: auto;
    //             margin: 10px 10px 10px;
    //             text-align: center;
    //             text-transform: uppercase;
    //             cursor: pointer;
    //         }
    //     }
    // }
}

.tf_top_breadcrumb_rate_wrap {
    @include borderRadius(12px);
    border: 1px solid $neutral_2;
    background: $white;
    margin: 0px 0 20px;
    padding: 18px;
}

.tf_box_wrap {
    padding-top: 18px;
    margin-top: 18px;
    border-top: 1px solid $neutral_2;
    gap: 30px;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }

    .red_text,
    .green_text {
        @include font($font-weight-medium, 14px, normal, $danger_7);
        padding-right: 16px;
        position: relative;
        margin: 0 0 2px 5px;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 3px;
            width: 14px;
            height: 14px;
            background: url('../../images/icons/less_rate.svg') no-repeat;
        }
    }

    .green_text {
        color: $success_7;

        &::before {
            background-image: url('../../images/icons/more_rate.svg');
        }
    }

    .box_title {
        @include font($font-weight-normal, 14px, normal, $neutral_5);
        margin-bottom: 14px;
    }

    .sh_box {
        position: relative;

        &:not(:last-child) {
            &::before {
                content: '';
                position: absolute;
                right: -14px;
                top: 0;
                height: 58px;
                width: 1px;
                background: $neutral_2;
            }
        }

        .sh_inquiry_rate {
            @include font($font-weight-bold, 20px, normal, $neutral_8);
            display: flex;
            align-items: flex-end;
        }
    }
}

.freight_filter_wrap {
    margin-bottom: 16px;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 15px;
    }

    .label {
        @include font($font-weight-semibold, 16px, normal, $neutral_8);
    }

    .right_actions_wrap {
        gap: 8px;

        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    .search_form {
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 330px;
        }

        form {
            input {
                @include font($font-weight-normal, 14px, normal, $neutral_8);
                @include borderRadius(12px);
                border: 1px solid $neutral_3;
                background: $white;
                padding: 9px 10px 9px 38px;
                width: 323px;

                &::placeholder {
                    color: $neutral_4;
                }

                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }

            button {
                position: absolute;
                top: 8px;
                left: 12px;
                font-size: 18px;
                padding: 0;
                line-height: 1;
                color: $neutral_8;
            }
        }
    }

    .filter_wrap,
    .currency_wrap,
    .upload_wrap {
        button {
            @include borderRadius(8px);
            border: 1px solid $neutral_3;
            box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
            padding: 8px 12px;
            height: 38px;
        }
    }

    .upload_wrap {
        button {
            @include font($font-weight-medium, 14px, normal, $neutral_8);

            img {
                margin-right: 4px;
            }
        }
    }

    .add_btn {
        button {
            @include button;

            i {
                font-size: 20px;
                margin-right: 7px;
            }
        }
    }
}

.table_view_modal {
    max-width: 1100px;

    @media only screen and (max-width: 1200px) {
        max-width: 95%;
    }

    .modal-header {
        position: relative;
    }

    .modal-title,
    .sub_modal_title {
        @include font($font-weight-bold, 18px, normal, $neutral_8);

        .close {
            cursor: pointer;
            opacity: 1;
            bottom: 0;
            margin: auto;
            margin-right: 20px;
            background-size: 13px;
        }
    }

    .sub_modal_title {
        margin: 0 0 0px;
    }

    .modal-content {
        background: #F9FAFB !important;
        box-shadow: 0px -1px 0px 0px $neutral_2 inset;
        @include borderRadius(10px);
    }

    .modal-body {
        padding: 0;
    }

    .table_view_data_wrap {
        padding: 20px;

        .view_details_wrap,
        .freigth_details_wrap,
        .charge_details {
            padding: 20px;
            background: $neutral_1;
        }

        .charge_details {
            @include borderRadius(0px 0 10px 10px);

            .view_data_wrap {
                display: flex;
                gap: 5px;

                @media only screen and (max-width: 767px) {
                    gap: 0;
                    flex-wrap: wrap;

                    .details {
                        width: 50%;
                    }
                }
            }
        }

        .view_details_wrap {
            .top_details {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid $neutral_2;

                @media only screen and (max-width: 767px) {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                }

                .details {
                    width: 100%;
                    flex-direction: row;
                    margin: 0;

                    @media only screen and (max-width: 767px) {
                        margin: 5px 0;
                    }
                }
            }

            @media only screen and (max-width: 767px) {
                .view_data_wrap .details {
                    width: 50%;
                }
            }
        }

        .view_data_wrap {
            position: relative;

            &:not(:last-child) {
                margin-bottom: 20px;
                border-bottom: 1px solid $neutral_2;
            }

            @media only screen and (max-width: 767px) {
                &::before {
                    display: none;
                }
            }
        }

        .details {
            width: 25%;
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            gap: 5px;

            .title {
                @include font($font-weight-medium, 14px, normal, $neutral_8);
                margin-right: 15px;
            }

            .data {
                @include font($font-weight-bold, 14px, normal, $neutral_8);
            }

            @media only screen and (max-width: 991px) {

                .title,
                .data {
                    font-size: 14px;
                }
            }

            @media only screen and (max-width: 767px) {
                width: 100%;

                &:nth-child(2n) {
                    padding-left: 0;
                }

                &:nth-child(2n + 1) {
                    padding-right: 0;
                }

                .title,
                .data {
                    font-size: 13px;
                }
            }
        }

        .freigth_details_wrap {
            background: $neutral_2;

            .left_freight_details,
            .right_freight_details {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .details {
                    width: 50%;
                }
            }

            @media only screen and (max-width: 767px) {
                .view_data_wrap {
                    flex-direction: column;
                }
            }
        }

        .accordion-item {
            @include borderRadius(12px);
            border: 1px solid $neutral_2;
            background: $white;
            margin-bottom: 20px;

            .accordion-button {
                padding: 0;
                background: transparent !important;
                box-shadow: none;

                .top_details {
                    padding: 0;
                    margin: 0;
                    border: 0;
                    width: 100%;
                }
            }

            .accordion-body {
                padding: 0;
                padding-top: 30px;
            }

            .right_freight_details {
                padding-left: 20px;
                border-left: 1px solid $neutral_2;

                @media only screen and (max-width: 767px) {
                    padding-left: 0;
                    border-left: 0;
                }
            }
        }

        &.port_local_view_wrap {
            .view_details_wrap {
                background: transparent;
                padding: 0;

                .top_details {
                    background: #F3F4F6;
                    padding: 10px 0;
                    @include borderRadius(10px);
                    border: 0;
                }
            }

            .accordion-item {
                .accordion-body {
                    padding: 0 20px;

                    .card-header {
                        padding: 10px;
                        background: #F3F4F6;
                    }

                    .card-body {
                        padding: 0;
                    }
                }
            }
        }

        .table_view_popup_table {
            table {
                width: 100%;
                border: 1px solid $neutral_2;
                @include borderRadius(12px);
                border-collapse: separate;
                border-spacing: 0;
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }

                th {
                    @include Sticky;
                    padding: 10px;
                    background: $neutral_1;
                    font-weight: $font-weight-medium;
                    text-align: center;

                    &:last-child {
                        @include borderRadius(0 10px 0 0);
                    }

                    &:first-child {
                        @include borderRadius(10px 0 0 0);
                    }
                }

                td {
                    padding: 10px;
                    text-align: center;
                    font-size: 13px;
                }
            }
        }

        .sub_accordion {
            .accordion-item {
                padding: 10px 15px;
            }
        }
    }

    .modal_no_data_wrap {
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: $neutral_8;
    }
}

.offcanvas-header {
    border-bottom: 1px solid $neutral_1;
}

.fcl_filter_sidebar_wrap {
    .btn_wrap {
        padding: 16px 0px 16px;
        gap: 8px;

        button {
            width: 100%;
        }
    }

    &.sales_filter_wrap {
        height: 78vh;
        overflow: auto;

        .timeline-item {
            .timeline-icon {
                width: 12px;
                height: 12px;
                background: #4848F7;
                border-radius: 50%;
                margin-top: 3px;
            }

            .timeline_title {
                color: #4848F7;
            }

            // &:nth-child(1) {
            //     .timeline_title {
            //         color: #00B42A;
            //     }
            // }

            // &:nth-child(2) {
            //     .timeline_title {
            //         color: #B25E09;
            //     }
            // }

            // &:nth-child(3) {
            //     .timeline_title {
            //         color: #4848F7;
            //     }
            // }
        }

        .divider {
            display: block;
            margin: 20px 0;
            height: 1px;
            width: 100%;
            background: $neutral_2;
        }

        .form-label {
            @include font($font-weight-semibold, 12px, normal, $neutral_5);
            letter-spacing: 0.24px;
            text-transform: uppercase;
            margin: 0 0 12px;
        }

        .noUi-target {
            margin: 18px 7px 15px;
            background: url('../../images/icons/range_indicator.svg');
            border: 0;
            box-shadow: none;
            height: 4px;

            .noUi-connect,
            .noUi-handle {
                background: $primary_7;
            }

            .noUi-handle {
                height: 16px;
                width: 16px;
                right: -9px;
                top: -6px;
                box-shadow: none !important;
                border: 0;
            }
        }

        .range_label {
            @include font($font-weight-medium, 14px, normal, $neutral_8);
            margin: 10px 0 0;
        }

        .radio_wrap {
            .form-check {
                width: 50%;
                padding-left: 28px;

                .form-check-input[type=radio] {
                    width: 18px;
                    height: 18px;
                    margin: 1px 0 0 -26px;
                }

                .form-check-label {
                    color: $neutral_8;
                }
            }
        }

        .checkbox_wrap {
            &.half_size {
                .form-check {
                    width: 50%;
                }
            }
        }
    }

    .form-select__menu {
        z-index: 99;
    }

    .custom_checkbox_wrap {
        display: flex;
        gap: 10px;

        .checkbox {
            margin: 0;
            border: 1px solid $neutral_2;
            border-radius: 5px;
            padding: 5px;
            position: relative;
            flex: 0 0 23%;
            max-width: 23%;
            cursor: pointer;

            &.active {
                background: $primary_7;

                .checkmark {
                    color: $white;
                }
            }

            .icon {
                width: 44px;
                height: 44px;
                margin: 0 auto 12px;

                img {
                    max-width: 100%;
                }
            }

            .checkmark {
                display: block;
                text-align: center;
                @include font($font-weight-medium, 11px, normal, $neutral_8);
            }
        }
    }
}

// ---------- create sales --------------------------------------
.create_sales_wrapper {
    min-height: calc(100vh - (#{$header-height} + 40px));
    @include borderRadius(12px);
    border: 1px solid $neutral_2;
    background: $white;
    padding: 18px;

    .create_sales_wrapper_inner {
        min-height: 600px;
    }

    .create_quote_top_back {
        .back_wrap {
            button {
                @include borderRadius(8px);
                border: 1px solid $neutral_4;
                background: $white;
                box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
                color: $neutral_8;
                width: 44px;
                padding: 0;
                height: 36px;
            }

            p {
                @include font($font-weight-semibold, 18px, 23px, $neutral_8);
            }
        }

        >button {
            padding: 0;
            height: 34px;
            min-width: 92px;
            @include borderRadius(8px);
        }
    }

    .create_sales_search_forms {
        .prof_wrap {
            @include borderRadius(12px);
            border: 1px solid $neutral_2;
            position: relative;
            align-items: center;
            padding: 16px;
            cursor: pointer;

            &.error {
                border-color: red;
            }

            .icon {
                @include borderRadius(999px);
                background: $primary_1;
                width: 38px;
                height: 38px;
                margin-right: 12px;
                flex: 0 0 38px;

                img {
                    max-width: 100%;
                }

                &.customer_name {
                    img {
                        width: 100%;
                    }
                }
            }

            .con {
                width: calc(100% - 50px);
                pointer-events: none;

                .value {
                    display: block;
                    margin-top: 4px;
                    @include font($font-weight-medium, 14px, normal, $neutral_4);

                    &.value_focus {
                        color: $neutral_8;
                    }
                }
            }

            i.mdi-chevron-down {
                font-size: 22px;
                color: $neutral_5;
                pointer-events: none;
            }

            .form-label {
                @include font($font-weight-semibold, 12px, normal, $neutral_8);
                margin: 0;
            }

            .form-select__control {
                border: 0;
                padding: 16px 16px 16px 60px;
                background: transparent;
                min-height: 70px;
                cursor: pointer;

                * {
                    margin: 0;
                    padding: 0;
                }

                .form-select__value-container {
                    @include font($font-weight-medium, 14px, normal, $neutral_4);
                    padding: 16px 0 0;
                }
            }

            .form-select__indicator-separator {
                display: none;
            }

            .form-select__indicator {
                svg {
                    path {
                        fill: $neutral_4;
                    }
                }
            }
        }

        .form-select__menu-list {
            padding: 0;

            .custom-option {
                display: flex;
                align-items: center;
                padding: 10px 16px;
                gap: 8px;
                border-bottom: 1px solid $neutral_2;

                div {
                    @include font($font-weight-medium, 16px, normal, $neutral_8);
                    letter-spacing: 0.16px;
                }
            }
        }

        .calendar_field_wrap {
            position: relative;
            padding: 0;
            min-height: 70px;

            .form-label {
                position: absolute;
                left: 60px;
                top: 16px;
            }

            .icon {
                position: absolute;
                top: 0;
                left: 13px;
                bottom: 0;
                margin: auto;
            }

            .con {
                pointer-events: all;
                width: 100%;

                .flatpickr-input {
                    padding: 32px 16px 16px 60px;
                    margin: 0;
                    border: 0;
                    @include font($font-weight-medium, 14px, normal, $neutral_8);

                    &::placeholder {
                        color: $neutral_4;
                    }
                }
            }
        }

        .number_field_wrap {
            .con {
                pointer-events: all;
            }

            .left_field {
                width: 100%;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input {
                    border: 0;
                    width: 100%;
                    @include font($font-weight-medium, 14px, normal, $neutral_8);
                    @include appearance;

                    &::placeholder {
                        color: $neutral_4;
                    }
                }
            }

            .common_dropdwon_btn_wrap {
                #more_menu {
                    @include font($font-weight-normal, 14px, normal, $neutral_5);
                }

                .common_dropdown_wrap {
                    width: 100px;
                    right: 0;
                    left: auto;

                    li {
                        padding: 9px 16px;
                        @include font($font-weight-medium, 16px, normal, $neutral_8);
                        letter-spacing: 0.16px;
                        border-bottom: 1px solid $neutral_2;

                        span {
                            @include font($font-weight-normal, 14px, normal, $neutral_5);
                            margin-right: 8px;
                        }
                    }
                }
            }
        }

        .quotation_select_port_wrap {
            position: relative;

            .quotation_from_wrap,
            .quotation_to_wrap {
                width: 50%;
                border: 1px solid $neutral_2;
                background: $white;

                &.openmenu {
                    box-shadow: 0px 0px 0px 4px $primary_1;
                    border: 1px solid $primary_7;
                }

                &.error {
                    border-color: red;
                }
            }

            .quotation_from_wrap {
                padding: 16px 20px;
                @include borderRadius(12px 0 0 12px);
                // border-right: 0 !important;
                @include borderRadius(12px);
            }

            .quotation_to_wrap {
                padding: 16px 20px 16px 40px;
                // @include borderRadius(0 12px 12px 0);
                @include borderRadius(12px);

                &.openmenu {
                    border-left-color: transparent;
                }
            }

            .location_wrap {
                .icon {
                    width: 52px;
                    height: 52px;
                    flex: 0 0 52px;
                    @include borderRadius(100%);
                    background: $primary_1;
                }

                .value {
                    @include font($font-weight-semibold, 20px, normal, $neutral_4);
                    display: flex;
                    align-items: center;

                    &.value_focus {
                        color: $neutral_8;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 6px;
                    }
                }
            }

            .swap_btn_wrap {
                position: absolute;
                left: 0;
                right: 0;
                width: 38px;
                height: 38px;
                @include borderRadius(50px);
                border: 1px solid $neutral_2;
                background: $white;
                margin: auto;
                bottom: 0;
                top: 0;
            }

            .location_dropdown_wrap {
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                z-index: 99;
                margin-top: 8px;
                @include borderRadius(12px);
                background: $white;
                box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.20);
                padding: 16px 20px;
                display: flex;
                gap: 20px;

                .field_input_wrap {
                    width: 100%;
                }

                .dropdown {
                    >a {
                        @include borderRadius(8px);
                        border: 1px solid $neutral_2;
                        background-color: $white;
                        font-weight: $font-weight-normal;
                        color: $neutral_8;
                        height: 38px;
                        line-height: 38px;
                        padding: 0 10px;
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 8px;
                            }
                        }

                        i {
                            margin-left: auto;
                            display: block;
                            line-height: 1;
                            font-size: 20px;
                            color: $neutral_8;
                        }
                    }

                    .dropdown-menu {
                        width: 100%;
                        padding: 0;
                        @include borderRadius(12px);
                        background: $white;
                        box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);

                        button.dropdown-item {
                            padding: 0;
                            cursor: pointer;

                            &:hover,
                            &.active {
                                background-color: $primary_1;
                            }
                        }

                        .custom-option {
                            display: flex;
                            align-items: center;
                            padding: 13px 16px;
                            gap: 8px;
                            border-bottom: 1px solid $neutral_2;

                            p {
                                @include font($font-weight-medium, 16px, normal, $neutral_8);
                                letter-spacing: 0.16px;
                            }
                        }
                    }
                }

                .form-select__menu-list {
                    .form-select__option {
                        padding: 13px 16px;
                        border-bottom: 1px solid $neutral_2;
                        @include font($font-weight-medium, 16px, normal, $neutral_8);
                        letter-spacing: 0.16px;

                        &:hover,
                        &.form-select__option--is-selected {
                            background-color: $primary_1;
                        }

                        &.form-select__option--is-focused {
                            background-color: rgba($color: $primary_1, $alpha: 0.5);
                        }

                        &.form-select__option--is-disabled {
                            &:hover {
                                background-color: transparent;
                            }

                            opacity: 0.4;
                        }
                    }
                }
            }

            // new -- 08-11-2023
            .location_wrap {
                .con {
                    width: 100%;

                    .form-select__control {
                        border: 0;
                        padding: 0;
                        min-height: auto;
                        box-shadow: none;
                        cursor: pointer;

                        .form-select__value-container {
                            padding: 0;

                            .form-select__placeholder,
                            .form-select__single-value {
                                @include font($font-weight-semibold, 20px, normal, $neutral_4);
                                margin: 0;
                            }

                            .form-select__single-value {
                                color: $neutral_8;
                            }
                        }

                        .form-select__indicators {
                            display: none;
                        }
                    }
                }
            }

            @media only screen and (max-width: 767px) {
                flex-direction: column;

                .quotation_from_wrap {
                    @include borderRadius(12px 12px 0 0);
                    border-right: 1px solid $neutral_2 !important;
                }

                .quotation_to_wrap {
                    @include borderRadius(0 0 12px 12px);
                }

                .quotation_from_wrap,
                .quotation_to_wrap {
                    width: 100%;
                    padding: 16px 10px;

                    &.openmenu {
                        border: 1px solid #4848F7 !important;
                    }
                }

                .location_wrap {
                    align-items: center;

                    .icon {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 40px;

                        img {
                            width: 28px;
                        }
                    }

                    .form-label {
                        font-size: 12px;
                        margin: 0 0 5px;
                    }

                    .value {
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    // new 08-11-2023
                    .con {
                        .form-select__control {
                            .form-select__value-container {
                                padding: 0;

                                .form-select__placeholder,
                                .form-select__single-value {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .location_dropdown_wrap {
                    flex-direction: column;
                    // position: static;
                }
            }
        }

        .search_view_btn {
            @include font($font-weight-medium, 14px, normal, $primary_7);
            box-shadow: none !important;
            border: 0 !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .create_quote_top_back {
            flex-direction: column;
            align-items: flex-start !important;
            gap: 10px;

            .back_wrap {
                p {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            >button {
                margin: 0 !important;
            }
        }

        .create_sales_search_forms .quotation_select_port_wrap {
            .location_dropdown_wrap {
                .dropdown {
                    .dropdown-menu .custom-option {
                        flex-wrap: wrap;
                        padding: 10px;

                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

.common_dropdwon_btn_wrap {
    position: relative;

    .common_dropdown_wrap {
        @include borderRadius(12px);
        background: $white;
        box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.20);
        margin: 8px 0 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 99;

        @media only screen and (max-height: 800px) {}

        li {
            cursor: pointer;

            &:hover,
            &.active {
                background-color: $primary_1;
            }

            .custom-option {
                display: flex;
                align-items: center;
                padding: 9px 16px;
                gap: 8px;
                border-bottom: 1px solid $neutral_2;

                p {
                    @include font($font-weight-medium, 14px, normal, $neutral_8);
                    letter-spacing: 0.16px;
                }

                @media only screen and (max-width: 767px) {
                    padding: 9px 10px;
                }
            }
        }

        &.shipping {
            li {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    right: 16px;
                    top: 0;
                    bottom: 0;
                    width: 20px;
                    height: 20px;
                    background: url("../../images/icons/ship-darkblue.svg") no-repeat;
                    margin: auto;
                }

                &.land::before {
                    background-image: url("../../images/icons/truck-darkblue.svg");
                }

                &.air::before {
                    background-image: url("../../images/icons/airplane_darkblue.svg");
                }

                &.active::before {
                    background-image: url("../../images/icons/ship-filled.svg");
                }

                &.active.land::before {
                    background-image: url("../../images/icons/truck_filled.svg");
                }

                &.active.air::before {
                    background-image: url("../../images/icons/airplane_filled.svg");
                }
            }
        }

        &.quantity_drop_wrap {
            li {
                .custom-option {
                    p {
                        margin: 0;
                    }
                }
            }

            .quantity_wrap {
                max-width: 100px;
                margin-left: auto;
                display: flex;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input {
                    display: block;
                    width: 100%;
                    border: 0;
                    text-align: center;
                    background: transparent;
                    @include appearance;
                    @include font($font-weight-semibold, 13px, normal, $neutral_8);
                }

                .minus,
                .plus {
                    width: 20px;
                    flex: 0 0 20px;
                    height: 20px;
                    background: $primary_7;
                    @include borderRadius(100%);
                    text-align: center;
                    line-height: 18px;
                    border: 0;
                    padding: 0;

                    i {
                        color: $white;
                        font-size: 11px;
                    }
                }
            }
        }

        &.scroller_dropdown {
            max-height: 220px;
            overflow-y: auto;
        }
    }

    &.incoterm_field_wrap {
        .prof_wrap .con {
            pointer-events: all !important;
        }

        .form-select__control {
            padding: 0 !important;
            min-height: auto !important;
            box-shadow: none !important;
            margin: 4px 0 0 !important;

            .form-select__value-container {
                padding: 0 !important;
            }

            .form-select__indicators {
                display: none;
            }
        }

        .form-select__menu-list {
            .form-select__option {
                padding: 8px 10px;
                border-bottom: 1px solid $neutral_2;
                @include font($font-weight-medium, 13px, normal, $neutral_8);
                letter-spacing: 0.16px;

                &:hover,
                &.form-select__option--is-selected {
                    background-color: $primary_1;
                }

                &.form-select__option--is-focused {
                    background-color: rgba($color: $primary_1, $alpha: 0.5);
                }

                &.form-select__option--is-disabled {
                    &:hover {
                        background-color: transparent;
                    }

                    opacity: 0.4;
                }
            }
        }
    }

    &.dropdown_smart_filter {
        .drop_search_box {
            padding: 15px 20px;
            border-bottom: 1px solid $neutral_2;

            .drop_search_input {
                position: relative;

                &::before {
                    content: "\eb56";
                    position: absolute;
                    left: 15px;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    color: #2D1C5B;
                    font-size: 18px;
                    font-family: boxicons !important;
                    line-height: 1;
                    bottom: 0;
                    margin: auto;
                }
            }

            input {
                width: 100%;
                height: 35px;
                border: 1px solid $neutral_2;
                padding: 0 15px 0 40px;
                @include borderRadius(8px);
            }
        }
    }

    &.currency_field_wrap {
        width: 125px
    }

    &.shipment_details_wrap .searchform {
        width: 340px;
    }

    @media only screen and (max-height: 750px) {
        &.bottom_drop_field {
            .common_dropdown_wrap {
                top: auto;
                bottom: 100%;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        &.bottom_drop_field {
            .common_dropdown_wrap {
                top: auto;
                bottom: 100%;
            }
        }

        &.shipment_details_wrap .searchform {
            width: 100%;
        }
    }

    @media only screen and (max-width: 420px) {
        &.shipment_details_wrap .searchform {
            .dimention_field {
                .input_field_wrap {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

.common_dropdown_wrap.container_drop_wrap {
    padding: 16px;

    .accordion-item {
        @include borderRadius(12px);
        border: 1px solid $neutral_2;
        background: $white;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .accordion-header {
        .accordion-button {
            background: transparent;
            padding: 12px 12px 12px 40px;
            @include font($font-weight-medium, 14px, normal, $neutral_8);
            box-shadow: none;

            &::after {
                right: auto;
                left: 16px;
                position: absolute;
                background-size: 13px;
                background-position: center;
            }
        }
    }



    .field_wrap {
        display: flex;
        gap: 12px;

        .error {
            border-color: red;
        }
        .error-select{
            outline: 1px solid red;
        }

        .input_field {
            width: 100%;
        }

        .field_dropdown {
            position: relative;

            input[type="number"] {
                padding-right: 60px;

                &:disabled+.dropdown>a.btn {
                    background: transparent;
                }
            }

            .dropdown {
                position: absolute;
                right: 1px;
                bottom: 1px;

                &>a {
                    @include font($font-weight-normal, 14px, 36px, $neutral_8);
                    padding: 0;
                    height: 36px;
                    padding-right: 10px;
                    background: $white;
                    @include borderRadius(8px);
                }
            }
        }
    }

    .accordion-body {
        padding-top: 0;
    }

    .dimention_field {
        .form-label {
            @include font($font-weight-semibold, 12px, normal, $neutral_5);
            letter-spacing: 0.24px;
            text-transform: uppercase;
        }

        .input_field_wrap {
            gap: 8px;

            .input_field {
                width: 100%;
                position: relative;

                &::before {
                    content: 'L';
                    position: absolute;
                    right: 12px;
                    top: 10px;
                    @include font($font-weight-normal, 14px, normal, $neutral_5);
                }

                &.dimention_w::before {
                    content: 'W';
                }

                &.dimention_h::before {
                    content: 'H';
                }

                input[type="number"] {
                    padding-right: 24px;
                }

                .dropdown {
                    .dimention_drop {
                        @include borderRadius(8px);
                        border: 1px solid $neutral_2;
                        background-color: $white;
                        width: 100%;
                        @include font($font-weight-normal, 14px, normal, $neutral_8);
                    }
                }
            }
        }
    }

    .btn_wrap {
        border-top: 1px solid $neutral_2;
        padding-top: 16px;
        margin-top: 16px;
        gap: 16px;
    }
}

// ---------------- search result
.search_result_wrap {
    .length_wrap {
        margin: 20px 0;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: $primary_1;
            margin: auto;
            top: 0;
            bottom: 0;
        }

        span {
            display: table;
            padding: 4px 12px;
            @include borderRadius(99px);
            background: $primary_1;
            @include font($font-weight-semibold, 13px, normal, $primary_7);
            text-transform: uppercase;
            position: relative;
            margin: 0 auto;
        }

        .quote_btn {
            position: absolute;
            right: 0;
            top: -7px;
        }

        @media only screen and (max-width: 767px) {
            margin: 20px 0 70px;

            .quote_btn {
                right: 0;
                top: auto;
                left: 0;
                margin: 0 auto;
                bottom: -50px;
            }
        }
    }

    .result_tab_wrap {
        ul.nav {
            gap: 12px;

            .nav-item {
                .nav-link {
                    border: 1px solid $neutral_3;
                    cursor: pointer;
                    @include borderRadius(12px);

                    &.active {
                        border-color: $primary_7;
                    }
                }

                &.currency_wrap {
                    flex: 0 0 85px;

                    .nav-link {
                        padding-left: 1;
                        padding-right: 1;
                    }
                }

                &.filter_wrap {
                    flex: 0 0 40px;

                    .nav-link {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .search_result_card_check_wrap {
        margin-top: 20px;

        .form-check {
            .form-check-input {
                width: 20px;
                height: 20px;
            }
        }
    }

    .search_result_card {
        padding: 32px 46px;
        width: calc(100% - 36px);
        position: relative;
        background: #F9FAFB;
        border: 1px solid $neutral_2;
        @include borderRadius(12px);
        overflow-x: auto;

        &.card_active,
        &:hover {
            border: 1px solid $primary_7;
            background: $primary_1;
        }

        .search_result_card_header,
        .search_result_accordion_details {
            min-width: 750px;
        }

        .card_img {
            min-width: 130px;
            max-width: 130px;
            padding-right: 20px;
            margin-right: 30px;
            border-right: 1px solid $neutral_2;

            .img {
                width: 48px;
                height: 48px;
                @include borderRadius(99px);
                border: 1px solid $neutral_2;
                background: #F9FAFB;

                img {
                    max-width: 100%;
                    @include borderRadius(10px);
                    padding: 6px;
                }
            }

            .title {
                color: $neutral_8;
            }
        }

        .middle_content {
            width: calc(100% - 390px);
            padding-right: 20px;

            .via_port_btn {
                @include font($font-weight-medium, 14px, normal, $primary_7);
            }

            .from_to_wrap {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 13px;
                    left: 30px;
                    right: 30px;
                    height: 1px;
                    border-top: 1px dashed $neutral_3;
                }

                .icon {
                    width: 28px;
                    height: 28px;
                    @include borderRadius(99px);
                    border: 1px solid $neutral_3;
                    background: #F9FAFB;
                    position: relative;
                }

                .from_loc,
                .to_loc {
                    @include font($font-weight-medium, 14px, normal, $neutral_8);
                    padding-top: 23px;
                    position: relative;
                    max-width: 70px;
                    min-width: 70px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 9px;
                        left: 0;
                        right: 0;
                        width: 10px;
                        height: 10px;
                        background: $primary_7;
                        @include borderRadius(100%);
                        margin: 0 auto;
                    }
                }

                &.multi_route {
                    .icon {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        top: 0;
                    }
                }
            }
        }

        .total_wrap {
            max-width: 230px;
            margin-left: auto;
            padding-left: 25px;
            border-left: 1px dashed $neutral_2;

            .view_detail_btn {
                min-width: 95px;
                padding: 0;
                box-shadow: none;
                border: 0;

                @media only screen and (max-width: 767px) {
                    min-width: 80px;
                }
            }

            .quote_now_btn {
                min-width: 100px;
            }
        }

        // &::before{
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: url("../../images/icons/result_card_shape.svg");
        // }
        @media only screen and (max-width: 1200px) {
            padding: 30px 25px;

            .card_img {
                min-width: 100px;
                padding-right: 20px;
                margin-right: 20px;
            }

            .middle_content {
                width: calc(100% - 330px);
                padding-right: 20px;
            }

            .total_wrap {
                padding-left: 15px;
            }
        }
    }

    .search_result_accordion_details {
        margin-top: 28px;

        .accordion-item {
            @include borderRadius(12px);
            border: 1px solid $neutral_2;
            background: $white;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .accordion-header {
            .accordion-button {
                background: transparent;
                padding: 12px 12px 12px 40px;
                @include font($font-weight-medium, 14px, normal, $neutral_8);
                box-shadow: none;

                &::after {
                    right: auto;
                    left: 16px;
                    position: absolute;
                    background-size: 13px;
                    background-position: center;
                }
            }

            .right_con {
                gap: 10px 30px;
            }
        }

        .accordion-body {
            padding-top: 0;
        }

        .radio_wrap {
            .pickup_details {
                max-width: calc(100% - 40%);
                width: 100%;

                span {
                    flex: 1 1 auto;
                    text-align: right;
                }
            }
        }
    }

    // Air freight card
    .air_freight_result_card {
        padding: 15px 25px 15px;
        width: calc(100% - 29px);

        .search_card_top {

            // padding-bottom: 10px;
            // margin-bottom: 10px;
            // border-bottom: 1px solid $neutral_2;
            .icon {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 15px;

                img {
                    max-width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .con {
                p {
                    @include font($font-weight-medium, 14px, normal, $neutral_8);
                    margin: 0;

                    &.flight_name {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }
                }
            }

            .carrier_name {
                @include font($font-weight-bold, 16px, normal, $neutral_8);
                margin: 0 20px 0 0;
            }
        }

        .search_card_middle_wrap {
            margin: 12px 0 0;
            padding: 8px 0 0;
            border-top: 1px solid #E5E7EB;
            position: relative;

            p {
                @include font($font-weight-normal, 13px, normal, $neutral_8);
                margin: 0;

                &.time {
                    margin: 5px 0;
                    font-weight: $font-weight-semibold;
                }
            }

            .icon_wrap {
                width: 28px;
                height: 28px;
                border-radius: 99px;
                border: 1px solid #D1D5DB;
                background: #F9FAFB;
                margin-left: 8px;
            }

            // new
            .search_card_middle {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 85px;
                    right: 85px;
                    height: 1px;
                    border-bottom: 1px dashed #D1D5DB;
                    top: -3px;
                    // bottom: 0;
                    // margin: auto;
                }

                &>div {
                    padding: 10px;
                    background: $white;
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    text-align: center;
                    position: relative;
                    max-width: 172px;
                    min-width: 172px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: -7px;
                        left: 0;
                        right: 0;
                        width: 10px;
                        height: 10px;
                        background: $primary_7;
                        border-radius: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }

        .middle_content {
            width: 100%;
            padding: 0;

            .from_to_wrap {
                .from_loc {
                    text-align: center;
                }
            }
        }

        .card_img {
            .img {
                overflow: hidden;

                img {
                    padding: 0;
                }
            }

            .flight_name {
                @include font($font-weight-medium, 14px, normal, $neutral_8);
            }
        }

        .card_corder-label {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            top: 0px;
            right: 0px;
            pointer-events: none;

            span {
                position: absolute;
                display: block;
                width: 165px;
                padding: 4px 0;
                background-color: $primary_8;
                box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
                color: $white;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
                text-transform: uppercase;
                text-align: center;
                left: 38px;
                top: 13px;
                transform: rotate(45deg);
                // left: -52px;
                // top: 15px;
                // transform: rotate(-45deg);
                font-size: 10px;
                background: $danger_8;
                height: 37px;
                display: flex;
                align-items: center;

                b {
                    max-width: 62px;
                    display: table;
                    margin: 0 auto;
                }
            }
        }

        .search_card_bottom {
            padding-right: 20px;

            .dropdown-menu {
                background: $white;
                padding: 0;

                .dropdown-item {
                    padding: 0;

                    .btn {
                        border-radius: 0;
                        border: 0;
                        border-bottom: 1px solid $neutral_2;

                        &:hover {
                            color: $white;
                            background: $primary_7;
                        }
                    }
                }
            }
        }

        .total_wrap {
            padding: 0 40px 0 20px;

            .total_price {
                margin-bottom: 0px;
                cursor: pointer;
            }
        }
    }
}

// ---------------- quotation modal---------------------
.quotation_modal_wrap {
    max-width: 1345px;

    @media only screen and (max-width: 1350px) {
        max-width: 98%;
    }

    .modal-body {
        min-height: 510px;
    }

    .right_wrap {
        position: relative;
        padding-right: 48px;

        .exchange_currency_wrap {
            .common_dropdwon_btn_wrap {
                #more_menu {
                    border: 1px solid $neutral_2;
                    @include borderRadius(8px 0 0 8px);
                    padding: 8px 12px;

                    &.openmenu {
                        border-top: 1px solid $primary_7;
                        border-bottom: 1px solid $primary_7;
                        border-left: 1px solid $primary_7;
                        box-shadow: 0px 0px 0px 4px $primary_1;
                    }

                    &.disable {
                        pointer-events: none;
                        opacity: 0.8;
                    }

                    i {
                        pointer-events: none;
                    }
                }

                .quantity_drop_wrap {
                    min-width: 100px;

                    li {
                        padding: 10px 16px;
                        border-bottom: 1px solid $neutral_2;
                        background: $white;

                        &.active {
                            background: $primary_1;
                        }
                    }
                }
            }

            .empty_input {
                border: 1px solid $neutral_2;
                padding: 8px 12px;
                @include borderRadius(8px 0px 0px 8px);

                input {
                    border: 0;
                    padding: 0 0px 0 5px;
                    width: 150px;
                    color: $primary_7;
                }
            }

            .exchange_rate {
                border: 1px solid $neutral_2;
                padding: 8px 12px;
                @include borderRadius(0 8px 8px 0);

                input {
                    border: 0;
                    padding: 0 0px 0 5px;
                    width: 60px;
                    color: $primary_7;
                }
            }
        }

        button.close {
            opacity: 1;
            padding: 0;
            bottom: 0;
            margin: auto;
            right: 10px;
        }
    }

    .main_accordion {
        .accordion-item {
            @include borderRadius(12px);
            border: 1px solid $neutral_2;
            background: $white;
            margin-bottom: 20px;

            .accordion-header {
                .accordion-button {
                    background: transparent;
                    padding: 18px 18px 18px 40px;
                    @include font($font-weight-medium, 14px, normal, $neutral_8);
                    box-shadow: none;

                    &::after {
                        right: auto;
                        left: 16px;
                        position: absolute;
                        background-size: 13px;
                        background-position: center;
                    }

                    .img {
                        width: 48px;
                        height: 48px;
                        @include borderRadius(99px);
                        border: 1px solid $neutral_2;
                        background: #F9FAFB;

                        img {
                            max-width: 100%;
                            @include borderRadius(100%);
                            padding: 5px;
                        }
                    }

                    .tag {
                        padding: 4px 12px;
                        @include borderRadius(99px);
                        background: $primary_1;
                        @include font($font-weight-semibold, 13px, normal, $primary_7);
                        text-transform: uppercase;

                        &.preferred {
                            background: $primary_1;
                            color: $primary_7;
                        }

                        &.cheaper {
                            background: $danger_1;
                            color: $danger_7;
                        }

                        &.faster {
                            background: $success_1;
                            color: $success_7;
                        }
                    }
                }

                .right_con {
                    gap: 10px 30px;
                }
            }

            .pickup_charge {
                padding: 8px 15px;
                border: 1px solid $neutral_2;
                @include borderRadius(10px);
            }
        }
    }

    .charges_wrap {
        [class*="col-"] {
            padding: 0 7px;

            @media only screen and (max-width: 1300px) {
                padding: 0 4px;
            }
        }

        .field_wrap {
            label {
                font-size: 13px;

                @media only screen and (max-width: 1300px) {
                    font-size: 11px;
                }
            }

            .error {
                border-color: red;
            }
            .error-select{
                outline: 1px solid red;
            }


            input,
            #more_menu {
                width: 100%;
                @include borderRadius(8px);
                border: 1px solid $neutral_2;
                background: $white;
                height: 38px;
                padding: 0 12px;
                font-size: 13px;
            }

            #more_menu {
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
            }

            .common_dropdown_wrap {
                li {
                    padding: 10px 16px;
                    border-bottom: 1px solid $neutral_2;
                    background: $white;
                }
            }

            .form-select__control {
                height: 38px;

                input {
                    height: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .main_accordion>.accordion-item {
            overflow-x: auto;

            &>.accordion-collapse,
            &>.accordion-header {
                min-width: 1025px
            }

            &>.accordion-collapse .accordion-body {
                padding-top: 0;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .modal-header {
            position: relative;
            flex-direction: column;
            align-items: self-start;
            gap: 10px 0;
        }

        .right_wrap {
            position: static;
            padding-right: 0;

            button.close {
                right: 20px;
            }
        }
    }
}

// --------------- preview modal --------------------------
.preview_modal_wrap {
    max-width: 595px;
    max-width: 795px;

    .modal-body {
        padding: 0;
        border: 0;
        background: $white url("../../images/img/preview_shape.png") repeat-y;
        background-size: 100%;
        position: relative;
    }

    .common_bg_wrap {
        padding: 12px;
    }

    .white_box {
        @include borderRadius(8px);
        border: 1px solid #EEEFF2;
        background: $white;
        padding: 10px;
    }

    .half_wrap {
        display: flex;
        gap: 4px;

        .half_box {
            width: 100%;

            .details:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    .title {
        @include font($font-weight-bold, 12px, normal, $neutral_8);
        margin-bottom: 6px;
    }

    .details {
        span {
            @include font($font-weight-normal, 12px, normal, $neutral_5);
            font-style: italic;
            display: block;
            margin-bottom: 3px;
        }

        p {
            @include font($font-weight-medium, 12px, normal, $neutral_8);
        }
    }

    .modal-content {
        // border: 0;
        // background: $white url("../../images/img/preview_shape.png") repeat-y;
        // position: relative;
        @include borderRadius(0px);

        .modal-header {
            position: absolute;
            left: calc(100% + 20px);
            display: block;
            border: 0;
            padding: 0;
            width: auto;

            button {
                background: $white;
                padding: 4px 16px 4px 12px;
                border: 0;
                @include borderRadius(8px);
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                @include font($font-weight-medium, 14px, normal, $neutral_8);
                border: 1px solid $neutral_3;
                box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);

                i {
                    font-size: 21px;

                    &.bx-plus {
                        transform: rotate(45deg);
                    }
                }
            }

            &.modal-left-button {
                left: auto;
                right: calc(100% + 20px);

                button {
                    white-space: nowrap;
                }
            }

            .format_image_wrap {
                width: 100%;
                border: 1px solid $neutral_3;
                background: $white;
                height: 100%;
                border-radius: 8px;
                padding: 5px;
                display: flex;
                grid-template-columns: 1fr 1fr;
                gap: 5px;
                flex-direction: column;
                margin: 0 auto;
                .img {
                    border: 1px solid $neutral_3;
                    border-radius: 4px;
                    overflow: hidden;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }

                    &.active {
                        border-color: $primary_7;
                    }

                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .preview_top_details {
        .left_details {
            p {
                @include font($font-weight-normal, 11px, normal, $neutral_8);

                b {
                    font-weight: $font-weight-semibold;
                }
            }
        }

        .right_logo {
            img {
                height: 44px;
            }
        }
    }

    .top_creation_details {
        &:not(:last-child) {
            padding-bottom: 6px;
            margin-bottom: 6px;
            border-bottom: 1px dashed $primary_1;
        }
    }

    .preview_table_wrap {
        margin: 8px 0;

        .preview_carrier_data {
            margin: 15px 0 5px;

            p {
                b {
                    @include font($font-weight-bold, 16px, normal, $neutral_8)
                }

                ;
            }
        }

        table {
            width: 100%;
            @include borderRadius(0 0 8px 8px);
            border: 1px solid #EEEFF2;
            background-color: $white;
            border-collapse: separate;
            caption-side: top;
            border-spacing: 0;

            caption {
                background: #F0F2F4;
                padding: 8px;
                @include borderRadius(8px 8px 0 0);

                p {
                    @include font($font-weight-semibold, 12px, normal, $neutral_8);
                    text-transform: uppercase;

                    .tag {
                        @include borderRadius(99px);
                        background: $primary_7;
                        padding: 3px 6px;
                        @include font($font-weight-semibold, 12px, normal, $white);

                        &.cheaper {
                            background: $danger_7;
                        }

                        &.faster {
                            background: $success_7;
                        }
                    }
                }
            }

            th {
                @include font($font-weight-normal, 12px, normal, $neutral_5);
                font-style: italic;
                padding: 8px;
                border-bottom: 1px solid #F0F2F4;
            }

            td {
                @include font($font-weight-medium, 12px, normal, $neutral_8);
                padding: 8px;
                border-bottom: 1px solid #F0F2F4;

                &.title_row {
                    padding: 4px 8px;
                    // border-bottom: 1px solid #F0F2F4;
                    background: #F0F2F4;
                    text-transform: uppercase;
                }
            }

            tfoot {
                tr {
                    td {
                        p {
                            text-align: right;

                            span {
                                margin-left: 5px;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    .preview_format2 {
        .preview_sidebar {
            @extend .white_box;
            height: 100%;

            .side_data {
                .label {
                    @include font($font-weight-medium, 12px, normal, $neutral_8);
                    margin: 0 0 7px;
                }

                .value {
                    @include font($font-weight-bold, 14px, normal, $neutral_8);
                    margin: 0;

                    &.blue_text {
                        color: $primary_7;
                    }
                }

                .sub_text {
                    @include font($font-weight-medium, 10px, normal, $neutral_5);
                }

                .pera {
                    @include font($font-weight-normal, 12px, normal, $black);

                    &.blue_pera {
                        color: $primary_7;
                    }
                }

                a {
                    text-decoration: underline !important;
                    @include font($font-weight-medium, 14px, normal, $primary_7);
                }
            }
        }

        h2 {
            @include font($font-weight-bold, 18px, normal, $black);
        }

        .address {
            max-width: 240px;

            p {
                margin: 0 0 5px;
                font-weight: 500;
            }
        }

        .preview_notes {
            p {
                margin: 0;
                font-size: 12px;
                @include font($font-weight-normal, 12px, normal, $neutral_8);
            }
        }

        .preview_additional_info {
            .add_info_content {
                @include font($font-weight-normal, 12px, normal, $neutral_8);
            }
        }

        .editor_details {
            ul {
                list-style: revert;
                padding: revert;
            }

            * {
                font-family: "IBM Plex Sans", sans-serif !important;
            }
        }
    }
}

.main_column_box_wrap {
    .column_box {
        border: 2px solid #eee;
        padding: 20px;
        min-height: 80vh;

        h2 {
            background: $primary_1;
            color: $neutral_8;
            margin: -20px -20px 20px;
            padding: 12px;
        }

        .btn_wrap {
            margin: 20px 0 0;
            gap: 15px;

            a {
                flex: 1 1 auto;
            }
        }
    }
}

.confirm_modal_wrap .modal-header {
    border: 0;
}

// -------------- port/local
.sub_field_wrap.disabled {
    pointer-events: none;
    opacity: 0.7;
}

.air_compare_form {
    gap: 10px 0;

    .form-select__control {
        min-height: 32px;
        max-height: 32px;
        overflow-y: auto;

        .select2-selection.form-select__indicator {
            padding: 4px 8px;
        }
    }

    .form-control {
        padding: 6px 12px;
    }

    .form-select__menu-list {
        .form-select__option {
            color: $black;

            label {
                margin: 0 0 0 5px;
            }

            &.form-select__option--is-selected {
                background-color: $primary_1;
                color: $neutral_8;
            }

            &.form-select__option--is-disabled {
                opacity: 0.5;
            }
        }
    }
}

.air_table_wrap {
    table {
        thead {
            tr {
                &:first-child:not(:last-child) {
                    th {
                        background: transparent;
                        border: 3px solid #F1F1FE;
                        text-align: center;
                        padding: 13px 12px;

                        i {
                            display: none;
                        }

                        &.hide {
                            font-size: 0;
                        }

                        &:not(.hide) {
                            background: rgb(6, 6, 150, 0.7);
                            color: #fff;
                        }

                        span {
                            justify-content: center;
                        }
                    }
                }

                &:not(:first-child) {
                    th {
                        border-radius: 0;
                        border: 3px solid #ffffff;
                        padding: 10px 12px;
                    }
                }
            }
        }
    }
}

.method1 {
    font-size: 13px;
    line-height: 27px;
    font-weight: 400;
}

.method2 {
    font-size: 13px;
    line-height: 27px;
    font-weight: 500;
}

.outbox h4 {
    height: 60px;
}

.outbox {
    background-color: #fadcff;
    border: blue;
}

.circlebox {
    // height: 63px;
    padding: 12px 20px;
}

.progress {
    font-weight: 600;
}

.innerbox2 {
    background-color: #007bff;
    border-radius: 28px;
    // width: 32%,
    width: 100%;
}

.progress-circle {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: conic-gradient(#007bff 0% 60%, #e9ecef 60% 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-text {
    position: absolute;
    font-size: 0.8rem;
    color: #007bff;
    font-weight: bold;
}

.custom-text {
    font-size: 11px;
    font-weight: 600;
    // background-color: #f8efff;
    border-radius: 99px !important;
    padding: 4px 8px;
    background-color: #F1F1FE;
    // color: #a83eff;
    color: #4848F7;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.card {
    &:nth-child(1) {
        // background-color:#7239EA0A;
        border: 1px solid #7239EA33;
    }

    &:nth-child(2) {
        //background-color:#EA83390A;
        border: 1px solid #EA833933;
    }

    &:nth-child(3) {
        //background-color:#22C55E0A;
        border: 1px solid #22C55E33;
    }
}

.quotationcard {
    &:nth-child(1) {
        background-color: #7239EA0A;
        border: 1px solid #7239EA33;
    }

    &:nth-child(2) {
        background-color: #EA83390A;
        border: 1px solid #EA833933;
    }

    &:nth-child(3) {
        background-color: #22C55E0A;
        border: 1px solid #22C55E33;
    }
}

.circle {
    width: 64px;
    height: 64px;
    position: relative;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-container {
    margin-left: 18px;
}

.btn-checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    position: relative;
  }

  .btn-checkbox-selected {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: white;
  }

  .bi-check {
    font-size: 12px;
  }


.circlebox h3 {
    font-size: 18px;
    font-weight: 600;
}

.circle span {
    position: absolute;
    display: block;
    // width: 100%;
    // height: 100%;
    text-align: center;
    color: #000000;
    /* Text color */
    line-height: 47px;
    // font-size: 12px;
    font-weight: 500;
    font-size: 14px;
    // font-family: Helvetica, Arial, Geneva, sans-serif;
    font-family: "IBM Plex Sans", sans-serif;
    transition: font-size 0.2s ease-out;
}

.circle:before {
    position: absolute;
    content: "";
    background-color: transparent;
    width: 64px;
    height: 64px;
    // width: 46px;
    // height: 46px;
    border: 7px solid #f7d7ff;
    border-radius: 100%;
    transition: width 0.2s ease-out, height 0.2s ease-out, border-width 0.2s ease-out;
}

.circle .percentage-bar {
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    position: absolute;
    // clip-path: inset(0 0 0 50%);
}

.circle.percentage-60 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #7239EA;
    transform: rotate(216deg);
}

.circle.percentage-40 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #EA8339;
    transform: rotate(144deg);
}

.circle.percentage-100 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #22C55E;
    transform: rotate(360deg);
}

.circle1 {
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    position: relative;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle1-container {
    margin-left: 18px;
}

.circle1 span {
    position: absolute;
    display: block;
    // width: 100%;
    // height: 100%;
    text-align: center;
    line-height: 47px;
    // font-size: 12px;
    font-weight: 500;
    font-size: 14px;
    // font-family: Helvetica, Arial, Geneva, sans-serif;
    transition: font-size 0.2s ease-out;
}

.circle1:before {
    position: absolute;
    content: "";
    background-color: transparent;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border: 7px solid #ffdbd3;
    /* Default border color */
    border-radius: 100%;
    transition: width 0.2s ease-out, height 0.2s ease-out, border-width 0.2s ease-out;
}

.circle1 .percentage-bar {
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    position: absolute;
    clip-path: inset(0 0 0 50%);
}

.circle1.percentage-60 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #fd2e00;
    /* Color for 60% */
    transform: rotate(216deg);
    /* Rotation for 60% */
}

.circle1.percentage-40 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #fd2e00;
    /* Color for 40% */
    transform: rotate(144deg);
    /* Rotation for 40% */
}

/* Add additional percentage styles as needed */
.circle1.percentage-100 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #9ce3ad;
    /* Color for 100% */
    transform: rotate(360deg);
    /* Full rotation for 100% */
}




.circle2 {
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    position: relative;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle2-container {
    margin-left: 18px;
}

.circle2 span {
    position: absolute;
    display: block;
    // width: 100%;
    // height: 100%;
    text-align: center;
    color: #000000;
    /* Text color */
    line-height: 47px;
    // font-size: 12px;
    font-weight: 500;
    font-size: 14px;
    // font-family: Helvetica, Arial, Geneva, sans-serif;
    transition: font-size 0.2s ease-out;
}

.circle2:before {
    position: absolute;
    content: "";
    background-color: transparent;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border: 7px solid rgb(195, 255, 195);
    /* Default border color */
    border-radius: 100%;
    transition: width 0.2s ease-out, height 0.2s ease-out, border-width 0.2s ease-out;
}

.circle2 .percentage-bar {
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    position: absolute;
    clip-path: inset(0 0 0 50%);
}

.circle2.percentage-60 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid green;
    /* Color for 60% */
    transform: rotate(216deg);
    /* Rotation for 60% */
}

.circle2.percentage-40 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #fd2e00;
    /* Color for 40% */
    transform: rotate(144deg);
    /* Rotation for 40% */
}

/* Add additional percentage styles as needed */
.circle2.percentage-60 .percentage-bar:after {
    content: "";
    position: absolute;
    // width: 46px;
    // height: 46px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 7px solid #22C55E;
    /* Color for 100% */
    transform: rotate(360deg);
    /* Full rotation for 100% */
}

.colBoxMain {
    display: flex;
    justify-content: space-between;
    // background: #edecec;
    border: 1px solid #E5E7EB;
    background: #F9FAFB;
    border-radius: 22px;
    // width: 100%;
    padding: 0 16px;
}

.mainCol {
    margin-top: 12px;
}

.colBox {
    // border: 1px solid #c9c9c9;
    padding-top: 0;
    // border-radius: 22px;
    // background: #f3f2f2;
    margin-top: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
    width: 100%;
    border: none;

    .card {
        background-color: #ffffff;
        border: 1px solid #E5E7EB;
        border-radius: 20px;
        padding: 12px;

        .card-header {
            padding: 0;
            padding-bottom: 12px;
        }
    }
}

.colBox:nth-child(-n+3) {
    // border: 1px solid #c9c9c9;
    // border-radius: 22px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

/* Add more percentage classes as needed */
.profile {
    width: 40px;
    /* or appropriate size */
    height: auto;
    /* maintain aspect ratio */
    border-radius: 50%;
    /* optional for rounded image */
}

img.profile1 {
    padding-left: 5px;
    padding-right: 0px;
    width: 26px;
    margin-right: 8px;
}

.mobScreen {
    gap: 14px;
}

@media screen and (max-width:600px) {
    .innerbox2 {
        width: 100%;
    }

    .mobScreen {
        flex-direction: column;
    }
}

.transfer-img {
    position: relative;
    width: 40px;

    .swap_btn_wrap {
        position: absolute;
        left: 0;
        right: 0;
        width: 38px;
        height: 38px;
        @include borderRadius(50px);
        border: 1px solid $neutral_2;
        background: $white;
        margin: auto;
        bottom: 0;
        top: 0;
        margin-right: 0px;
    }
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(169 167 167 / 43%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loaderCard {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18%;
    background-color: white;
}

/* On mouse-over, add a deeper shadow */
.loaderCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.loaderCardContainer {
    padding: 1px 10px;
}

.loaderCard img {
    margin-left: 10px;
}

.loadingText {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 20px;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4, jump-none) infinite;
}

@keyframes l {
    to {
        clip-path: inset(0)
    }
}


/* PricingSection.css */

.pricing-section {
    background: linear-gradient(180deg, #fff 0%, #f8f9fa 100%);
}

.card-price {
    font-size: 2rem;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-group-header {
    background-color: #f8f9fa; /* or #f1f3f5, whichever matches your design */
    border-radius: 9999px; /* ensures a full pill shape */
    border: 1px solid #e0e0e0; /* subtle border */

    .btn {
        border: none; /* remove default border */
        transition: background-color 0.3s;
    }

    .btn:hover {
        background-color: #e9ecef; /* light hover effect for non-active buttons */
    }

    .btn-primary {
        background-color: #ffffff; /* Make the "active" button white (like a pill) */
        color: #495057;           /* Dark text color */
        border: 1px solid #e0e0e0;/* subtle border for the active pill */
    }
}

.image-placeholder {
    width: 100px;           /* Set width for the circle */
    height: 100px;          /* Set height for the circle */
    border-radius: 50%;     /* Makes the container circular */
    border: 2px dashed #ccc; /* Dashed border, like your screenshot */
}

.disabled-link {
    pointer-events: none;
    text-decoration: none;
    cursor: not-allowed;
    //background: rgb(200 199 199 / 14%);
    background: linear-gradient(90deg, #e2e2e2 0%, rgba(241, 241, 254, 0) 100%);
}

.clickable-span {
    pointer-events: auto; /* Re-enables click events on the child */
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.custom-switch {
    display: flex;
    align-items: center;
    gap: 5px;
}

.switch-label {
    background: #6366f1;
    color: white;
    padding: 4px 10px;
    border-radius: 5px;
    font-weight: bold;
}

input[type="checkbox"]:checked + .switch-label {
    background: #6366f1;
}

input[type="checkbox"]:not(:checked) + .switch-label {
    background: #d1d5db;
    color: black;
}

.sticky-box {
    position: fixed;
    right: 20px;
    padding: 15px;
    color: white;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}

.footer-fixed {
    position: absolute;
    bottom: 10px;
    right: 20px;
}

.move-bottom {
    bottom: 20px;
}

.instant-rate-booking-accordion-header:not(.collapsed) {
    background-color: transparent !important;
    color: inherit !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    background-color: transparent !important;
    color: inherit !important;
    box-shadow: none !important;
}


.instant-rate-booking-form-checkbox {
    width: 24px; /* Make checkbox larger */
    height: 24px;
    border-radius: 50%; /* Make it circular */
    border: 2px solid #007bff; /* Blue border */
    appearance: none; /* Hide default UI */
    outline: none;
    cursor: pointer;
    background-color: white;
    transition: 0.3s ease-in-out;
}

.instant-rate-booking-form:checked {
    background-color: #007bff; /* Blue fill when checked */
    border: 2px solid #0056b3;
}

.instant-rate-booking-form:checked::after {
    content: "✔";
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.extraSalesRowDetails {
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #2D1C5B;
}


.introjs-hint {
    display: inline-block !important;
    position: relative !important;
    z-index: 9999 !important;
    cursor: pointer !important;
}

.introjs-hint-dot {
    width: 12px !important;
    height: 12px !important;
    background: red !important; /* 🔥 Change color to test visibility */
    border-radius: 50% !important;
    display: block !important;
}
