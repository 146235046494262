.settings_wrapper {
    .settings_sidebar {
        .active {
            color: var(--bs-sidebar-menu-item-active-active-color) !important;
            background: linear-gradient(90deg, #F1F1FE 0%, rgba(241, 241, 254, 0) 100%);
        }
    }
    .dz-message {
        img{
            object-fit: contain;
        }
    }
}